import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Proizvodi | Protupožarna zaštita - FSB d.o.o.',
  description: 'U svojoj ponudi nudimo široku paletu proizvoda renomiranih svjetskih proizvođača na tržištu te izvođenje rješenja pasivne protupožarne zaštite.',
}

const Homepage = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
      ]}
    />
    <Hero
      description="U svojoj ponudi nudimo široku paletu proizvoda renomiranih svjetskih proizvođača na tržištu te izvođenje rješenja pasivne protupožarne zaštite."
      slim
      subtitle="Svi proizvodi"
      title="Proizvodi"
    />
    <Products />
  </Page>
)

export default Homepage